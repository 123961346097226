import React from "react"

import Container from "../layout/Container"

export const MarkdownPageTemplate = ({ content, className }) => (
  <Container>
    <div
      className={`py-32 lg:py-48 prose lg:prose-xl ${className}`}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  </Container>
)

export default MarkdownPageTemplate
